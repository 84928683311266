/* global Component, uuidv4 */
class rangeFilterComponent extends Component {

    static name() {
        return "rangeFilterComponent";
    }

    static componentName() {
        return "rangeFilterComponent";
    }

    getProps() {
        return ['filterField', 'open_by_default'];
    }

    getWatch() {
        return {
            '$route': 'generateIntervals',
            '$store.state.itemTimeFlag': 'generateIntervals',
            '$store.state.itemUpdate': 'calculateResults'
        };
    }

    data() {
        return {
            display: true,
            isMobile: $(window).width() <= 560,
            show_clearfilter: false,
            filterIntervals: [],
            renderView: false,
            inView: 0
        };
    }

    getComputed() {
        return {
            isCurrency:function (){
                return this.filterField.ItemField.search(/price/gmi) !== -1;
            },
            filterName: function () {
                return this.filterField.ItemField;
            },
            intervalBegin: function () {
                return this.filterIntervals[0][2].name;
            },
            intervalEnd: function () {
                return this.filterIntervals[this.filterIntervals.length - 1][2].name;
            }
        };
    }

    mounted() {
        return async function () {
            let self = this;
            this.generateIntervals();
            if (this.open_by_default != 'undefined') {
                this.display = this.open_by_default;
            } else {
                this.display = !this.isMobile;
                $(window).resize(function () {
                    self.isMobile = $(window).width() <= 560;
                    self.display = !self.isMobile;
                });
            }
        };
    }
    
    getMethods() {
        return {
            generateIntervals:this.generateIntervals,
            calculateResults: this.calculateResults,
            resetCountInterval:this.resetCountInterval,
            launchFilter:this.launchFilter,
            clearFilter:this.clearFilter,
            fetchData:this.fetchData,
        };
    }

    generateIntervals(){
        if(!this.filterField || !this.filterField.FilterCollection)
            return [[0,100]];
        
        let interval = this.filterField.FilterCollection.split(',');
        let lastElement = null;
        if(interval.length > 0)
            lastElement = interval[interval.length - 1];
        else
            lastElement = interval[0];
        let rangeInterval = [];
        let last = null;
        for(let inter of interval){
            //let operator = null;
            let filter = {};
            filter.name = uuidv4();
            filter.type = 'rangeFilter';
            filter.itemCount = 0;
            filter.hiddens = new Map();
            filter.active = false;
            if(last == null) {
                if (inter == 0)
                    continue;
                filter.name = `range-${this.filterField.ItemField}-less-${inter}`;
                filter.condition = `parseFloat(x.${this.filterField.ItemField})<=${inter}`;
                filter.result = true;
                rangeInterval.push([0, inter,filter]);
            }
            else {
                filter.name = `range-${this.filterField.ItemField}-between-${inter}-${last}`;
                filter.condition = `parseFloat(x.${this.filterField.ItemField})<=${inter} && parseFloat(x.${this.filterField.ItemField})>=${last}`;
                filter.result = true;
                rangeInterval.push([last, inter,filter,'range' + uuidv4()]);
            }
            last = inter;
        }
        let Endfilter = {};
        Endfilter.type = 'rangeFilter';
        Endfilter.itemCount = 0;
        Endfilter.hiddens = new Map();
        Endfilter.active = false;
        Endfilter.name = `range-${this.filterField.ItemField}-end-${lastElement}`;
        Endfilter.condition = `parseFloat(x.${this.filterField.ItemField})>${lastElement}`;
        Endfilter.result = true;
        rangeInterval.push([lastElement,lastElement,Endfilter]);
        this.filterIntervals = rangeInterval;
        this.calculateResults();
    }

    calculateResults(){
        let items = this.$store.getters.getItems;
        this.resetCountInterval();
        for(let x of items.filter(e=>e.visible == true)){
            for(let interval of this.filterIntervals) {
                let cond = interval[2].condition;
                let result = interval[2].result;
                try{
                    let isVisible = (eval(cond) == result);
                    if(isVisible) {
                        this.inView = this.inView + 1;
                        interval[2].itemCount = interval[2].itemCount + 1;
                    }
                }
                catch(error){
                    // eslint-disable-next-line no-console
                    console.log('Error in range filter',error , x, interval);
                }
            }
        }
    }

    resetCountInterval(){
        for(let interval of this.filterIntervals) {
            interval[2].itemCount = 0;
        }
    }

    async launchFilter(filters){
        filters[2].active = true & filters[2].active;
        this.show_clearfilter = true;
        //console.log('New Filter',filters[2]);
        await this.$store.dispatch('addFilter', filters[2]);
        this.emitEvent("filter-select");
    }

    async clearFilter(){
        this.show_clearfilter = false;
        await this.$store.dispatch('restoreFilter');
    }

    fetchData() {
        this.renderView = !this.renderView;
        this.calculateResults();
    }

    getTemplate() {
        return `<div class="filter" v-show="inView>0">
                    <p class="filter-title" @click="display = !display">
                        {{tr(filterField.ItemField)}}
                        <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                        <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                    </p>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul class="unstyled">
                          <template v-for="interval of filterIntervals">
                                <template v-if="interval[2].itemCount>0">
                                    <li :key="'li-'+interval[2].name" v-if="interval[2].name == intervalBegin" >
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input1-'+interval[2].name" class="custom-control-input" v-model="interval[2].active"  type="checkbox" :id="interval[2].name"  :name="interval[2].name" @click="launchFilter(interval)"/>
                                            <label :key="'label-'+interval[2].name"  :for="interval[2].name" class="custom-control-label">Hasta 
                                                 <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})
                                            </label>
                                        </div>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else-if="interval[2].name == intervalEnd" >
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input2-'+interval[2].name" class="custom-control-input" v-model="interval[2].active" type="checkbox" :id="interval[2].name" :name="interval[2].name"  @click="launchFilter(interval)"/>
                                            <label :key="'label2-'+interval[2].name" :for="interval[2].name" class="custom-control-label" >Más de <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})</label>
                                        </div>
                                    </li>
                                    <li :key="'li-'+interval[2].name" v-else>
                                        <div class="custom-control custom-checkbox">
                                            <input :key="'input3-'+interval[2].name" class="custom-control-input" v-model="interval[2].active" type="checkbox" :id="interval[2].name" @click="launchFilter(interval)" />
                                            <label :key="'label3-'+interval[2].name" :for="interval[2].name" class="custom-control-label"><template v-if="isCurrency">$</template>{{interval[0]}} a <template v-if="isCurrency">$</template>{{interval[1]}} ({{interval[2].itemCount}})</label>
                                        </div>
                                    </li>
                                </template>
                          </template>
                        </ul>
                    </section>
                </div>`;
    }
}

rangeFilterComponent.registerComponent();